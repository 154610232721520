<template>
  <div class="building">
    <NavBar :list="[{ name: '楼栋管理' }]" name="商铺管理"></NavBar>
    <el-form style="margin-top: 20px" inline>
      <el-form-item label="">
        <el-autocomplete
          v-model="params.name"
          @change="getList"
          clearable
          @select="handleselect"
          :fetch-suggestions="(query, cb) => querySearch(query, cb, 'name')"
          placeholder="楼栋名称"
        ></el-autocomplete>
      </el-form-item>
      <el-form-item label="">
        <myButton @click="visible = true" :accessId="69691">
          新增楼栋
          <template #preImage>
            <img src="../../../unit/img/zj.png" alt="" />
          </template>
        </myButton>
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      v-loading="loading"
      :header-cell-style="{
        'text-align': 'center',
        background: 'rgb(245, 245, 245)',
      }"
      :cell-style="{ 'text-align': 'center' }"
    >
      <el-table-column prop="name" label="楼栋名称"></el-table-column>
      <el-table-column prop="tier_number" label="楼层总数"></el-table-column>
      <el-table-column prop="create_at" label="创建时间"></el-table-column>
      <el-table-column prop="update_at" label="更新时间"></el-table-column>
      <el-table-column label="操作">
        <template #default="{ row }">
          <pop tips="编辑" @click="edit(row)" :accessId="69692">
            <img class="icon" src="../../../assets/img/icon/edit.png" alt />
          </pop>
          <pop tips="删除" popLeft :accessId="69693" @myclick="del(row)">
            <img class="icon" src="../../../assets/img/icon/deleted.png" alt />
          </pop>
        </template>
      </el-table-column>
    </el-table>
    <div class="apiPage">
      <el-pagination
        :total="total"
        :current-page="params.pageNum"
        :page-size="params.pageSize"
        layout="total,prev,pager,next"
        next-text="下一页"
        prev-text="上一页"
        @current-change="currentChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      :title="type ? '新增楼栋' : '编辑楼栋'"
      :visible.sync="visible"
      @close="diaclose"
      :close-on-click-modal="false"
    >
      <el-form
        label-position="right"
        :model="editForm"
        label-width="100px"
        ref="form"
      >
        <el-form-item
          label="楼栋名称:"
          prop="name"
          :rules="$setRules('请输入楼栋名称')"
        >
          <el-input
            class="public-input"
            v-model="editForm.name"
            placeholder="请输入楼栋名称"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="楼层:"
          prop="tier_number"
          :rules="$setRules('请输入楼层总数')"
        >
          <!-- <el-input class="public-input" v-num type="number" @change="floorset" v-model="editForm.tier_number"
                        placeholder="请输入楼层总数"></el-input> -->
          <!-- <span>{{editForm.tier_number}}</span> -->
          <myButton @click="floorset">新增楼层</myButton>
        </el-form-item>
        <el-form-item label="楼层名称:" v-if="editForm.floor_names.length > 0">
          <div
            v-for="(item, index) in editForm.floor_names"
            style="margin-top: 5px"
            :key="index"
          >
            <el-input
              class="public-input"
              clearable
              v-model="item.name"
              :placeholder="`楼层${index + 1}名称`"
            ></el-input>
            <myButton
              left
              @click="floordel(index, item.building_floor_id)"
              type="error"
              >删除</myButton
            >
          </div>
          <span style="color: #e90000" v-if="hascomplate()">请填写完整</span>
        </el-form-item>
      </el-form>
      <template #footer>
        <myButton @click="visible = false">取消</myButton>
        <myButton
          left
          type="primary"
          :disabled="hascomplate()"
          @click="handleEdit"
          >确定</myButton
        >
      </template>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      params: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      tableData: [],
      loading: false,
      editForm: { name: "", tier_number: "", floor_names: [] },
      visible: false,
      type: true,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;
      this.tableData = [];
      this.$request
        .HttpPost("/building/list", this.params)
        .then((res) => {
          if (res.data) {
            this.tableData = res.data.list;
            this.total = res.data.total;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async querySearch(query, cb, type) {
      let { data } = await this.$request.HttpPost("/building/list", {
        name: query,
      });
      if (data && data.list) {
        data.list.forEach((item) => {
          item.value = item[type];
        });
        return cb(data.list);
      }
      cb([]);
    },
    handleselect(value) {
      this.params.name = value.name;
      this.getList();
    },
    currentChange(page = 1) {
      this.params.pageNum = page;
      this.getList();
    },
    edit(row) {
      this.$request
        .HttpGet("/building/edit", { id: row.building_id })
        .then((res) => {
          this.type = false;
          this.editForm.name = row.name;
          this.editForm.tier_number = row.tier_number;
          this.editForm.building_id = row.building_id;
          this.getfloorlist(row.building_id);
        });
    },
    getfloorlist(id) {
      this.$request.HttpGet("/floor/list", { building_id: id }).then((res) => {
        if (res.data) {
          this.visible = true;
          this.editForm.floor_names = res.data.list || [];
        }
      });
    },
    del(row) {
      this.$myconfirm("确认删除该楼栋?").then(() => {
        this.$request
          .HttpPost("/building/delete", { ids: row.building_id })
          .then((res) => {
            this.$common.notifySuccess("删除成功");
            this.getList();
          });
      });
    },
    handleEdit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let url = this.type ? "/building/add" : "/building/edit";
          this.$request.HttpPost(url, this.editForm).then((res) => {
            this.visible = false;
            this.$common.notifySuccess("操作成功");
            this.getList();
          });
        }
      });
    },
    diaclose() {
      this.$refs.form.clearValidate();
      this.editForm.name = "";
      this.editForm.tier_number = "";
      this.editForm.id = "";
      this.type = true;
      this.editForm.floor_names = [];
    },
    floorset() {
      if (!this.editForm.floor_names) {
        this.editForm.floor_names = [];
      }
      this.editForm.floor_names.push({ name: "" });
      this.editForm.tier_number = this.editForm.floor_names.length;
    },
    floordel(index, id) {
      if (!id) {
        this.editForm.floor_names.splice(index, 1);
        this.editForm.tier_number = this.editForm.floor_names.length;
        return false;
      }
      this.$request
        .HttpPost("/floor/check", { building_floor_id: id })
        .then((res) => {
          this.editForm.floor_names.splice(index, 1);
          this.editForm.tier_number = this.editForm.floor_names.length;
        });
    },
    hascomplate() {
      return this.editForm.floor_names.some((item) => {
        return !item.name || item.name == "";
      });
    },
  },
};
</script>
<style></style>
